<template>
	<div class="flex-grow">
		<Disclosure as="section" aria-labelledby="filter-heading" class="grid items-center">
			<h2 id="filter-heading" class="sr-only">
				Filters
			</h2>
			<div class="flex justify-between">
				<div>
					<div class="mx-auto flex max-w-7xl space-x-6 divide-x divide-jmi-purple4 text-sm">
						<div class="flex items-center space-x-6 divide-x divide-jmi-purple4 text-sm">
							<div>
								<DisclosureButton as="div">
									<FormsButton dusk="filters-button" color="lightPurpleOutline">
										{{ countActiveFilters }} filter{{ countActiveFilters === 1 ? '' : 's' }}
										<FunnelIcon v-if="!countActiveFilters" class="w-5 h-5 ml-2 -mr-1 stroke-1" aria-hidden="true" />
										<SolidFunnelIcon v-if="countActiveFilters" class="w-5 h-5 ml-2 -mr-1" aria-hidden="true" />
									</FormsButton>
								</DisclosureButton>
							</div>
							<div v-if="countActiveFilters" class="pl-6 hidden sm:block">
								<button type="button" class="text-jmi-purple3" @click="clearAll">
									Clear all
								</button>
							</div>
						</div>
					</div>
				</div>
				<div>
					<div class="mx-auto flex max-w-7xl justify-end">
						<FormsInput v-model="computedQuery" class="w-44 lg:w-full" input-class="pl-9">
							<template #prefix>
								<div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
									<MagnifyingGlassIcon class="h-5 w-5 text-jmi-purple3" aria-hidden="true" />
								</div>
							</template>
						</FormsInput>
					</div>
				</div>
			</div>
			<DisclosurePanel class="border-y bg-white border-jmi-purple4 py-6 mt-5">
				<div class="mx-auto grid max-w-7xl grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 px-4 text-sm sm:px-6 sm:gap-6 lg:px-6">
					<fieldset v-for="(filter, key) in computedFilters" :key="key" class="min-w-0">
						<legend class="block font-medium">
							{{ filter.title }}
						</legend>
						<div :dusk="'filter-'+key" class="space-y-3 pt-2">
							<template v-if="filter.type === 'multiselect'">
								<template v-if="filter.options.length > 5">
									<FormsSelect multiple :model-value="currentFilterValues[key]" :name="`${key}[]`" :options="filter.options" @update:modelValue="(newValue) => setSingleFilterValue(key, newValue)" />
								</template>
								<template v-else>
									<div v-for="(option, optionIdx) in filter.options" :key="option.id" class="flex items-center text-base sm:text-sm">
										<input
											:id="`${key}-${optionIdx}`"
											:name="`${key}[]`"
											:value="option.id"
											type="checkbox"
											class="h-4 w-4 flex-shrink-0 rounded border-gray-300 text-jmi-purple2 focus:text-jmi-purple2 focus:ring-jmi-purple2"
											:checked="isChecked(key, option.id)"
											@input="setFilterValue(key, option.id, $event.target.checked)"
										>
										<label :for="`${key}-${optionIdx}`" class="ml-3 min-w-0 flex-1 text-gray-600">{{ option.label }}</label>
									</div>
								</template>
							</template>
							<template v-else-if="filter.type === 'date-range'">
								<FormsInput type="date-range" :placeholder="filter.placeholder" :model-value="currentFilterValues[key]" @update:modelValue="(newValue) => setSingleFilterValue(key, newValue)" />
							</template>
							<template v-else>
								<div v-for="(option, optionIdx) in filter.options" :key="option.id" class="flex items-center text-base sm:text-sm">
									<input
										:id="`${key}-${optionIdx}`"
										:name="key"
										:value="option.id"
										type="radio"
										class="h-4 w-4 flex-shrink-0 rounded border-gray-300 text-jmi-purple2 focus:text-jmi-purple2 focus:ring-jmi-purple2"
										:checked="isChecked(key, option.id)"
										@input="setSingleFilterValue(key, option.id)"
									>
									<label :for="`${key}-${optionIdx}`" class="ml-3 min-w-0 flex-1 text-gray-600">{{ option.label }}</label>
								</div>
							</template>
						</div>
					</fieldset>
				</div>
			</DisclosurePanel>
		</Disclosure>
	</div>
</template>

<script setup lang="ts">
import {Disclosure, DisclosureButton, DisclosurePanel} from '@headlessui/vue'
import {FunnelIcon, MagnifyingGlassIcon} from '@heroicons/vue/24/outline'
import {FunnelIcon as SolidFunnelIcon} from '@heroicons/vue/24/solid'
import {computed, PropType} from "vue";
import type { TableFilters } from "~/utils/types";

const props = defineProps({
	currentFilterValues: {
		type: Object as PropType<TableFilters>,
		required: true
	},
	filters: {
		type: Object,
		required: true,
	},
	query: {
		type: String,
		required: false
	},
});

const emit = defineEmits(["update:currentFilterValues", "update:query"]);

const computedFilters = computed(() => {
	let filters: typeof props.currentFilterValues = {};
	Object.keys(props.filters).forEach((key) => {
		const filter = props.filters[key];

		if (typeof filter.options !== 'undefined') {
			filter.type = filter.type ?? 'multiselect';
		}

		filters[key] = filter;
	});

	return filters;
});

const computedQuery = computed({
	get() {
		return props.query;
	},
	set(value) {
		emit('update:query', value);
	}
});

const clearAll = () => {
	let newFilterValues = {} as Record<string, any>;

	Object.keys(props.currentFilterValues).map((key) => {
		newFilterValues[key] = undefined;
	});

	emit("update:currentFilterValues", newFilterValues);
};

const countActiveFilters = computed(() => {
	return Object.keys(props.currentFilterValues).filter((key) => {
		return props.currentFilterValues?.[key] !== undefined;
	}).length;
});

const setSingleFilterValue = (key: string, value: any) => {
	let newFilterValues = {...props.currentFilterValues};
	newFilterValues[key] = value === null ? undefined : value;
	emit("update:currentFilterValues", newFilterValues);
}

const setFilterValue = (key: string, id: string | number | boolean, checked: boolean) => {
	let newValue = props.currentFilterValues[key];

	if (!newValue) {
		newValue = [];
	}

	if (checked) {
		if (newValue.indexOf(id) === -1) {
			newValue.push(id);
		}
	} else {
		if (newValue.indexOf(id) !== -1) {
			newValue.splice(newValue.indexOf(id), 1);

			if (newValue.length === 0) {
				newValue = undefined;
			}
		}
	}

	setSingleFilterValue(key, newValue);
}

const isChecked = (key: string | number, id: string | number | undefined): boolean => {
	if (props.currentFilterValues[key]) {
		if (typeof props.currentFilterValues[key] === "object") {
			return props.currentFilterValues[key].indexOf(id) !== -1;
		} else {
			return props.currentFilterValues[key] === id;
		}
	} else {
		return id === undefined;
	}
};

</script>
